import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import AuthProvider from './auth/authProvider';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './store/store';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'

 const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

 root.render(
  <BrowserRouter>
    <Provider store={store}>
      <AuthProvider>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <App />
        </LocalizationProvider>
      </AuthProvider>
    </Provider>
  </BrowserRouter>
);
