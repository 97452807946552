import { createSelector, createSlice, } from '@reduxjs/toolkit';

interface AuthState {
    token: string | null;
}

const initialState: AuthState = {
    token: null
};

const authenticationSlice = createSlice({
    name: 'authentication',
    initialState: initialState,
    reducers: {
        setStoreToken: (state, action) => {
            state.token = action.payload;
        },
        resetStoreToken: (state) => {
            state.token = initialState.token;
        }
    }
});
export const { setStoreToken, resetStoreToken } = authenticationSlice.actions;

const getState = (state: any) => state.auth;

export const getToken = createSelector(getState, (state) => state.token);

export default authenticationSlice.reducer;