import { Auth0Provider } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';
import { config } from '../config/config';

const AuthProvider = ({ children }: any) => {
    const domain = config.AUTH0_DOMAIN;
    const clientId = config.AUTH0_CLIENT_ID;
    const db_connection = config.DB_CONNECTION;
    const nav = useNavigate();

    const onRedirectCallback = (appState: any) => {
        nav(window.location.pathname);
    };

    return (
        <Auth0Provider
            domain={domain}
            clientId={clientId}
            authorizationParams={{
                redirect_uri: window.location.origin,
                connection: db_connection
            }}
            onRedirectCallback={onRedirectCallback}>
            {children}
        </Auth0Provider>
    );
};

export default AuthProvider;