import { ICompany } from '@imisight-types/company';
import { PayloadAction, createSelector, createSlice, } from '@reduxjs/toolkit';
import { getDateRangeFromToday } from '../../services/utils';

interface MainContentState {
    headerContent: string | null;
    companies: ICompany[] | null;
    dateRangeFilter: string[];
    dateRangeSelectValue: number;
}

const initialState: MainContentState = {
    headerContent: null,
    companies: null,
    dateRangeSelectValue: 30,
    dateRangeFilter: getDateRangeFromToday(30).map(date => date.toJSON())
};

const mainContentSlice = createSlice({
    name: 'mainContent',
    initialState: initialState,
    reducers: {
        setMainContentHeader: (state, action) => {
            state.headerContent = action.payload;
        },
        setAllCompanies: (state, action) => {
            state.companies = action.payload;
        },
        setDateRangeValue: (state, action) => {
            state.dateRangeSelectValue = action.payload;
        },
        setDateRangeFilter: (state, action: PayloadAction<Date[]>) => {
            state.dateRangeFilter = action.payload.map(date => date.toJSON());
        }
    }
});
export const { setMainContentHeader, setAllCompanies, setDateRangeValue, setDateRangeFilter } = mainContentSlice.actions;

const getState = (state: any) => state.mainContent as MainContentState;

export const getMainContentHeader = createSelector(getState, (state) => state.headerContent);
export const getAllCompanies = createSelector(getState, (state) => state.companies);
export const getDateRangeSelectValue = createSelector(getState, (state) => state.dateRangeSelectValue);
export const getDateRangeFilter = createSelector(getState, (state) => state.dateRangeFilter.map(date => new Date(date)));

export default mainContentSlice.reducer;