import { GetFeatureUsageResponse } from "@imisight-types/feature-usage";
import { config } from "../config/config";
import moment from "moment";
import axiosService from "./axios.service";

const featureUsageEndPoint: string | undefined = config.GATEWAY_URL;

export const getFeatureUsage = (featureName?: string, companyId?: string, dateRange?: Date[]) => {
    const body = { name: featureName, company: companyId, dateRange: dateRange?.map(date => moment(date).utc().toDate().getTime()) }
    return axiosService.api.post<GetFeatureUsageResponse>(`${featureUsageEndPoint}/feature-usage/feature`, body)
        .then((response) => { return response.data as GetFeatureUsageResponse })
        .catch((error) => { console.log(error); return null; });
}

export const getFeaturesActions = (featureNames?: string[], groupBy?: string, dateRange?: Date[]) => {
    let body: any = {
        types: featureNames,
        groupBy,
        dateRange: dateRange?.map(date => moment(date).utc().toDate().getTime())
    };

    return axiosService.api.post<any>(`${featureUsageEndPoint}/feature-usage/actions`, body)
        .then((response) => { return response.data as any })
        .catch((error) => {
            console.log(error);
            return null;
        });

}