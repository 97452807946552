import React from "react";
import { Routes, Route } from 'react-router-dom';
import Dashboard from '../Dashboard/dashboard';
import SearchPanel from "../Dashboard/search-panel";

export default function Router({ children }: any) {
  return (
    <Routes>
      <Route path="/" element={<Dashboard />}></Route>
      <Route path="search_panel" element={<SearchPanel />}></Route>
    </Routes>
  );
}