import axios from 'axios';


//#region Option 1 - Axios with Auth0

// const useAxiosWithAuth0 = () => {
//     const { getAccessTokenSilently } = useAuth0();

//     useEffect(() => {
//         const setupInterceptors = async () => {
//             const token = await getAccessTokenSilently();

//             axios.interceptors.request.use(
//                 async config => {
//                     config.headers.Authorization = `Bearer ${token}`;
//                     return config;
//                 },
//                 error => {
//                     return Promise.reject(error);
//                 }
//             );
//         };

//         setupInterceptors();
//     }, [getAccessTokenSilently]);

//     return axios;
// };

// export default useAxiosWithAuth0;

//#endregion


import type { AxiosInstance, AxiosRequestConfig } from 'axios';

class ApiService {
    api: AxiosInstance;

    constructor() {
        this.api = axios.create({});
    }

    updateToken(token: string | null) {
        if (token)
            this.api.interceptors.request.use(
                async config => {
                    config.headers.Authorization = `Bearer ${token}`;
                    return config;
                }
            );
        else {
            this.api.interceptors.request.clear();
        }
    }

    protected async get<T = any>(url: string, config?: AxiosRequestConfig): Promise<T> {
        const { data } = await this.api.get<T>(url, config);
        return data;
    }

    protected async put<T = any>(url: string, data?: any, config?: AxiosRequestConfig): Promise<T> {
        const { data: d } = await this.api.put<T>(url, data, config);
        return d;
    }

    protected async post<T = any>(url: string, data?: any, config?: AxiosRequestConfig): Promise<T> {
        const { data: d } = await this.api.post<T>(url, data, config);
        return d;
    }

    protected async delete<T = any>(url: string, config?: AxiosRequestConfig): Promise<T> {
        const { data } = await this.api.delete<T>(url, config);
        return data;
    }
}

export default new ApiService();