
// export const config = {
//     AUTH0_CLIENT_ID: process.env.AUTH0_CLIENT_ID || '7dmIHOIdq0ngxlPlahU75B83hbfvAMkS',
//     AUTH0_DOMAIN: process.env.AUTH0_DOMAIN || 'imisight.auth0.com',
//     GATEWAY_URL: process.env.REACT_APP_GATEWAY_URL || 'http://localhost:3000',
//     DB_CONNECTION: process.env.REACT_APP_DB_CONNECTION || 'dev'
// }


declare var configuration: any;

export const config = {
    AUTH0_DOMAIN: process.env.NODE_ENV === 'production' ? configuration.REACT_APP_AUTH0_DOMAIN : 'imisight.auth0.com',
    AUTH0_CLIENT_ID: process.env.NODE_ENV === 'production' ? configuration.REACT_APP_AUTH0_CLIENT_ID : '7dmIHOIdq0ngxlPlahU75B83hbfvAMkS',
    GATEWAY_URL: process.env.NODE_ENV === 'production' ? configuration.REACT_APP_GATEWAY_URL : "http://localhost:3000",
    DB_CONNECTION: process.env.REACT_APP_DB_CONNECTION || 'dev'
};