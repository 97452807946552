import { useDispatch, useSelector } from "react-redux";
import { getDateRangeSelectValue, setDateRangeFilter, setDateRangeValue } from "../../../../store/slices/main-content.slice";
import { getDateRangeFromToday } from "../../../../services/utils";
import { closeModal, openModal } from "../../../../store/slices/modal.slice";
import { MenuItem, Select } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { useRef } from "react";
import moment, { Moment } from "moment";

const DateRangeSelector = () => {
    const dispatch = useDispatch();
    const startDate = useRef<Moment | null>(null);
    const endDate = useRef<Moment | null>(moment());

    function onDateFilterChanged(event: any) {
        const value = event.target.value;
        dispatch(setDateRangeValue(value));
        const dateRangeMiliseconds = getDateRangeFromToday(value);
        if (value !== -1) {
            dispatch(setDateRangeFilter(dateRangeMiliseconds));
        }
    }
    const currentDateRangeSelectValue = useSelector(getDateRangeSelectValue);

    const defaultDateRange = {
        label: 'Last 30 Days',
        value: 30,
    };

    const shortcutsItems: { label: string, value: number, onClick?: Function }[] = [
        {
            label: 'Last 7 days',
            value: 7,
        },
        defaultDateRange,
        {
            label: 'Last 60 Days',
            value: 60,
        },
        {
            label: 'Last 90 days',
            value: 90,
        },
        {
            label: 'Custom',
            value: -1,
            onClick: () => dispatch(openModal({
                title: 'Insert date range',
                content:
                    <>
                        <DatePicker value={startDate.current} onChange={(val) => startDate.current = val}></DatePicker>
                        <DatePicker value={endDate.current} onChange={(val) => endDate.current = val}></DatePicker>
                    </>,
                actions: [
                    { label: 'Cancel', onClick: () => dispatch(closeModal()) },
                    {
                        label: 'Set', onClick: () => {
                            if (!startDate.current || !endDate.current) return;
                            dispatch(setDateRangeFilter([startDate.current.startOf('day').toDate(), endDate.current.endOf('day').toDate()]));
                            dispatch(closeModal());
                        }
                    }
                ],
            })),
        },
    ];

    return (
        <Select className='date-range-select' value={currentDateRangeSelectValue} onChange={onDateFilterChanged}>
            {shortcutsItems && shortcutsItems.map((item) =>
                <MenuItem key={item.label} onClick={() => item.onClick?.call(this)} value={item.value}>{item.label}</MenuItem>
            )}
        </Select>
    );
}

export default DateRangeSelector;