import { FunctionComponent, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getAllFeatures, setSelectedFeature } from "../../store/slices/features.slice";
import styled from "styled-components";

interface FeatureSelectorContainerProps {

}


const FeatureOption = styled.div`
  margin: 0.2rem;
  margin-top: 0.5rem;
  cursor: pointer;
  padding: 0.5rem;
  border-radius: 0.5rem;
  
  .selected{
    background-color: #2E3D4F;
  }
`;


const FeatureSelector = styled.div`
  background-color: #1F2D36;
  font-size: 1.1rem;
  padding-left: 1.2rem;
  padding-right: 1.2rem;
  color: white;
`;

const FeatureSelectorContainer: FunctionComponent<FeatureSelectorContainerProps> = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const allFeatures = useSelector(getAllFeatures);

  function onFeatureClick(feature: any) {
    navigate(feature.key);
    dispatch(setSelectedFeature(feature));
  }


  return (
    <FeatureSelector>
      <h2>Features</h2>
      {allFeatures.map((feature) =>
        <FeatureOption key={feature.key} className={feature.selected ? 'selected' : ''}
          onClick={() => onFeatureClick(feature)}>{feature.value}</FeatureOption>)}
    </FeatureSelector>
  );
}

export default FeatureSelectorContainer;