import styled from 'styled-components';

const DashboardWrapper = styled.div`
  width: 100%;
  overflow: hidden;
  text-align: center;
`;

const Dashboard = () => {
  return (
    <DashboardWrapper>
      <h1>Welcome to Imisight Analytics</h1>
      <h3>Please select a Feature from the list</h3>
    </DashboardWrapper>
  );
}

export default Dashboard;