import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from '@reduxjs/toolkit';
import authReducer from "./slices/authSlice";
import mainContentReducer from './slices/main-content.slice';
import featuresReducer from './slices/features.slice';
import modalReducer from './slices/modal.slice';

const rootReducer = combineReducers({
    features: featuresReducer,
    mainContent: mainContentReducer,
    auth: authReducer,
    modal: modalReducer,
});

export default configureStore({
    reducer: rootReducer,
});


