import moment from "moment";

export const groupByKey = (data: any[], groupBy: string) => {
    return data?.reduce((acc, item) => {
        const keys = groupBy.split('.');
        let keyValue = item;

        keys.forEach(key => {
            keyValue = keyValue[key];
        });

        if (!acc[keyValue]) {
            acc[keyValue] = [];
        }
        acc[keyValue].push(item);
        return acc;
    }, {});
}

export const getDateRangeFromToday = (days: number) => {
    const today = moment();
    const firstDate = moment(today).subtract(days - 1, 'day').startOf('day');
    const SecondDate = moment(today).endOf('day');
    return [firstDate.toDate(), SecondDate.toDate()];
}

export const getEnumKeyValueByValue = (enumObj: any, compareVal: any) => {
    for (const [key, value] of Object.entries(enumObj)) {
        if (value === compareVal) {
            return { key, value };
        }
    }
    return null;
}