import { useState } from 'react';
import styled from 'styled-components';
import Login from '@mui/icons-material/Login';
import Logout from '@mui/icons-material/Logout';
import { useAuth0 } from "@auth0/auth0-react";
import TopMenu from './Top-menu/top-menu';
import { MenuFormat } from "../Shared/Models/ui.models";
import packageJson from '../../../package.json'; // replace with the path to your package.json file

// Styled components
const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #2E3D4F;
  padding: 1rem;
  height: auto;

  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: space-between;
  }
`;

const Logo = styled.div``;

const VersionText = styled.a`
  color: white;
  font-size: 0.8rem;
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  color: white;
  gap: 1rem;
  @media (min-width: 768px) {
    justify-content: space-between;
  }
`;

const Header = () => {
  const { logout, isAuthenticated, loginWithRedirect } = useAuth0();

  const [menuItems, setMenuItems] = useState<{ title: string, class: string, icon: any, customFunction?: any, isVisibale: boolean }[]>([
    { title: 'Login', class: 'menu search-map', icon: Login, customFunction: (() => loginWithRedirect()), isVisibale: !isAuthenticated },
    {
      title: 'Logout', class: 'menu gallery', icon: Logout, customFunction: (() => logout({
        logoutParams: {
          returnTo: window.location.origin
        }
      })), isVisibale: isAuthenticated
    }
  ]);

  return (
    <HeaderWrapper>
      <Logo className="imisight-logo" />
      <IconWrapper>
        <VersionText>Version: {packageJson.version}</VersionText>
        <TopMenu position={{ x: 0, y: 0 }} format={MenuFormat.textAndIcons} items={menuItems}></TopMenu>
      </IconWrapper>
    </HeaderWrapper>
  );
}
export default Header;

