import './styles/scroller.css'
import './App.css';
import { useEffect } from 'react';
import Layout from './components/Layout/layout';
import { useAuth0 } from "@auth0/auth0-react";
import Popup from './components/Modals/Popup';
import ApiService from './services/axios.service';
import LoginPage from './components/Login/login';

function App() {
  const { isAuthenticated, isLoading, error, getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    (async function getToken() {
      try {
        const token = await getAccessTokenSilently();
        ApiService.updateToken(token);
      } catch (error) {
        ApiService.updateToken(null);
      }
    })()
  }, [isAuthenticated]);

  return (
    <>
      {error && !isLoading && <p>Auth Error</p>}
      {!error && isLoading && <p>Loading...</p>}
      {!error && !isLoading && isAuthenticated && (
        <>
          <Layout></Layout>
          <Popup></Popup>
        </>
      )}
      {!isAuthenticated && !isLoading &&
        <LoginPage></LoginPage>
      }
    </>
  );
}

export default App;