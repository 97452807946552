import './Popup.css';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { closeModal, getIsModalOpen, getModalActions, getModalContent, getModalTitle } from '../../store/slices/modal.slice';
import { useDispatch, useSelector } from 'react-redux';

const Popup = () => {

  const isOpen = useSelector(getIsModalOpen);
  const title = useSelector(getModalTitle);
  const content = useSelector(getModalContent);
  const actions = useSelector(getModalActions);

  const dispatch = useDispatch();

  return (
    <Dialog open={isOpen} onClose={() => dispatch(closeModal())}>
      {title && (<DialogTitle>{title}</DialogTitle> || null)}
      {content && (<DialogContent>{content}</DialogContent> || null)}
      {actions.length && (
        <DialogActions>
          {actions.map(action =>
            <Button disabled={action.disbaled?.bind(this)} onClick={() => action.onClick()} color={action.color || "primary"}>
              {action.label}
            </Button>
          )}
        </DialogActions>
        || null)}
    </Dialog>
  );
};

export default Popup;

