import { PayloadAction, createSelector, createSlice, } from '@reduxjs/toolkit';

interface ModalState {
    isOpen: boolean;
    title?: string;
    content?: any;
    actions: ModalAction[];
}

interface ModalAction {
    label: string;
    disbaled?: Function;
    onClick: Function;
    color?: "error" | "info" | "inherit" | "primary" | "secondary" | "success" | "warning";
}

const initialState: ModalState = {
    isOpen: false,
    actions: []
};

const modalSlice = createSlice({
    name: 'modal',
    initialState: initialState,
    reducers: {
        openModal: (state, action: PayloadAction<{ title: string; content: React.ReactNode; actions: ModalAction[] }>) => {
            state.isOpen = true;
            state.title = action.payload.title;
            state.content = action.payload.content;
            state.actions = action.payload.actions;
        },
        closeModal: (state) => {
            state.isOpen = false;
            state.title = undefined;
            state.content = undefined;
            state.actions = [];
        }
    }
});
export const { openModal, closeModal } = modalSlice.actions;

const getState = (state: any): ModalState => state.modal;

export const getIsModalOpen = createSelector(getState, (state) => state.isOpen);
export const getModalTitle = createSelector(getState, (state) => state.title);
export const getModalContent = createSelector(getState, (state) => state.content);
export const getModalActions = createSelector(getState, (state) => state.actions);

export default modalSlice.reducer;