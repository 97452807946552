import { ChartConfiguration } from 'chart.js';
import Chart from 'chart.js/auto'
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { getFeatureUsage, getFeaturesActions, } from '../../services/feature-usage.service';
import { getSelectedFeature, setSelectedFeature } from '../../store/slices/features.slice';
import { useDispatch, useSelector } from 'react-redux';
import { getAllCompanies, getDateRangeFilter } from '../../store/slices/main-content.slice';
import { Card, CircularProgress } from '@mui/material';
import { FeatureUsageTypes } from '../Shared/Models/feature-usage.enums';
import DateRangeSelector from '../Shared/Components/Daterange-Selector/daterange-selector';
import moment from 'moment';
import { getEnumKeyValueByValue } from '../../services/utils';
import { GetFeatureUsageResponse, GetUsageActionsResponse } from '@imisight-types/feature-usage';

const PieChartCard = styled.div`
    display: flex;
    flex-direction: column;

`;

const LineChartCard = styled.div`
    grid-column: span 2;
`;

const CardHeader = styled.div`
 font-size: 1rem;
 margin-bottom: 0.5rem;
`;

const ChartContainerGrid = styled.div`
    display: grid;
    padding: 0.5rem;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    grid-template-rows: repeat(3, max-content);
    gap: 1rem;
    overflow: auto;
    
    @media (max-width: 1400px) {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    *{
        align-self: stretch;
        display: grid;
        margin: 0;
    }
`;

const MainContentHeader = styled.div`
  margin: 0;
  max-height: 3rem;
  display: flex;
  justify-content: space-between;

  h2 {
    margin: 0;
    padding: 0.5rem;
  }
`;

const MainContent = styled.div`
  margin: 0;
  overflow: hidden;
  padding: 1rem;
  width: 100%;
  display: grid;
  grid-template-rows: 3rem 1fr;

  @media (max-width: 1200px) {
    padding: 0.5rem;
  }
`;

const DateFilterContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

const SuccessRateData = styled.div`
    padding: 0.5rem
`;

const SuccessRateContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-around;
`;

const Percentage = styled.div`
    align-self: center;
    font-size: 1.7rem;
`;

const SearchPanel = () => {
    const dateRangeFilter = useSelector(getDateRangeFilter);
    const mainContentHeader = "Search Panel";
    const selectedFeature = useSelector(getSelectedFeature);
    const allCompanies = useSelector(getAllCompanies);

    useEffect(() => {
        featureUsageByCompany?.destroy();
        searchTypeUsage?.destroy();
        searchHistoryUsage?.destroy();
    }, [dateRangeFilter]);

    useEffect(() => {
        console.log('Search Panel component has mounted');

    }, []);

    const [successRate, setSuccessRate] = useState<{ totalSuccess: number, totalFailed: number, totalSearches: number } | null>(null);

    const dispatch = useDispatch();
    if (!selectedFeature)
        dispatch(setSelectedFeature(getEnumKeyValueByValue(FeatureUsageTypes, FeatureUsageTypes.search_panel)));

    const [featureUsageByCompany, setFeatureUsageByCompany] = useState<Chart | null>(null);
    const [searchTypeUsage, setSearchTypeUsage] = useState<Chart | null>(null);
    const [searchHistoryUsage, setSearchHistoryUsage] = useState<Chart | null>(null);

    useEffect(() => {
        return () => {
            featureUsageByCompany?.destroy();
            searchTypeUsage?.destroy();
            searchHistoryUsage?.destroy();
        }
    }, []);

    useEffect(() => {
        if (!dateRangeFilter || !allCompanies) return;
        getFeatureUsage(`search_panel`, undefined, dateRangeFilter).then((res: GetFeatureUsageResponse | null) => {
            if (!res) return;
            const { companysUsage } = res;
            if (!companysUsage) return;
            const chartConfig: ChartConfiguration = {
                type: 'pie',
                data: {
                    labels: companysUsage.map((feature) => allCompanies.find(company => company._id === feature.companyId)?.name),
                    datasets: [
                        {
                            data: companysUsage.map((feature) => feature.count),
                            backgroundColor: companysUsage.map((feature) => `rgb(${Math.floor(Math.random() * 255)}, ${Math.floor(Math.random() * 255)}, ${Math.floor(Math.random() * 255)}, 1)`),
                            hoverOffset: 4,
                        },
                    ],
                },
            };
            featureUsageByCompany?.destroy();
            setFeatureUsageByCompany(new Chart('featureUsageByCompany', chartConfig));
        });
    }, [dateRangeFilter, allCompanies]);


    useEffect(() => {
        getFeaturesActions(["search_for_results"], 'payload.0.searchType', dateRangeFilter).then((res: GetUsageActionsResponse) => {
            if (!res) return;
            const { data, groupBy } = res;
            if (!data || !groupBy) return;
            createSearchTypeUsageChart(res);
            createSearchHistoryChart(res);
        });
    }, [dateRangeFilter]);

    useEffect(() => {
        if (!dateRangeFilter) return;
        getFeaturesActions(["search_request_failed", "search_request_success", "search_for_results"], 'action', dateRangeFilter).then((res: GetUsageActionsResponse) => {
            if (!res) return;
            const { groupBy } = res;
            if (!groupBy) return;
            const totalSuccess = groupBy['search_request_success']?.length || 0;
            const totalFailed = groupBy['search_request_failed']?.length || 0;
            const totalSearches = groupBy['search_for_results']?.length || 0;
            setSuccessRate({ totalSuccess: totalSuccess, totalFailed: totalFailed, totalSearches: totalSearches });
        });
    }, [dateRangeFilter]);

    function createSearchHistoryChart(res: GetUsageActionsResponse) {
        const { data, groupBy } = res;
        if (!data || !groupBy) return;

        const startDate = moment(dateRangeFilter[0]);
        const endDate = moment(dateRangeFilter[1]);

        const lastGroupByDay = data.reduce((acc: any, item) => {
            const date = new Date(item.timestamp);
            const day = date.toISOString().split('T')[0];
            if (!acc[day]) {
                acc[day] = [];
            }
            acc[day].push(item);
            return acc;
        }, {});

        const days = [];
        const dayDiff = endDate.diff(startDate, 'days') + 1;

        for (let i = 0; i < dayDiff; i++)
            days.push(moment(startDate).add(i, 'days').format('YYYY-MM-DD'));


        const chartConfig: ChartConfiguration = {
            type: 'line',
            data: {
                labels: days,
                datasets: [
                    {
                        label: "Number of searches",
                        data: days.map((day) => lastGroupByDay[day]?.length || 0),
                        backgroundColor: `rgb(${Math.floor(Math.random() * 255)}, ${Math.floor(Math.random() * 255)}, ${Math.floor(Math.random() * 255)}, 1)`,
                        hoverOffset: 4,
                    },
                ],
            },
        };
        searchHistoryUsage?.destroy();
        setSearchHistoryUsage(new Chart('searchHistoryChart', chartConfig));
    }

    function createSearchTypeUsageChart(res: GetUsageActionsResponse) {
        const { data, groupBy } = res;
        if (!data || !groupBy) return;
        const chartConfig: ChartConfiguration = {
            type: 'pie',
            data: {
                labels: Object.keys(groupBy),
                datasets: [
                    {
                        data: Object.values(groupBy).map((items) => (items as any[]).length),
                        backgroundColor: data.map((feature) => `rgb(${Math.floor(Math.random() * 255)}, ${Math.floor(Math.random() * 255)}, ${Math.floor(Math.random() * 255)}, 1)`),
                        hoverOffset: 4,
                    },
                ],
            },
        };
        searchTypeUsage?.destroy();
        setSearchTypeUsage(new Chart('searchTypeUsage', chartConfig));
    }

    return (
        <MainContent>
            {mainContentHeader &&
                <MainContentHeader>
                    <h2>{mainContentHeader}</h2>
                    <DateFilterContainer>
                        <div>
                            {dateRangeFilter[0].toLocaleDateString()} - {dateRangeFilter[1].toLocaleDateString()}
                        </div>
                        <DateRangeSelector></DateRangeSelector>
                    </DateFilterContainer>
                </MainContentHeader>}
            <ChartContainerGrid className='charts-container'>
                <PieChartCard>
                    <Card className='card'>
                        <CardHeader>Feature Usage by Company</CardHeader>
                        <canvas id="featureUsageByCompany"></canvas>
                        {!featureUsageByCompany && <CircularProgress className='circular-progress'></CircularProgress>}
                    </Card>
                </PieChartCard>
                <PieChartCard>
                    <Card className='card'>
                        <CardHeader>Search Type Usage</CardHeader>
                        <canvas id="searchTypeUsage"></canvas>
                        {!searchTypeUsage && <CircularProgress className='circular-progress'></CircularProgress>}
                    </Card>
                </PieChartCard>
                <LineChartCard>
                    <Card className='card line-chart'>
                        <CardHeader>Search History</CardHeader>
                        <canvas id="searchHistoryChart"></canvas>
                        {!searchHistoryUsage && <CircularProgress className='circular-progress'></CircularProgress>}
                    </Card>
                </LineChartCard>
                <PieChartCard>
                    <Card className='card'>
                        <CardHeader>Search success rate</CardHeader>
                        {successRate ? <SuccessRateContainer>
                            <div>
                                <SuccessRateData>Total searches : {successRate?.totalSearches}</SuccessRateData>
                                <SuccessRateData>Total Success: {successRate?.totalSuccess}</SuccessRateData>
                                <SuccessRateData>Total Failed: {successRate?.totalFailed}</SuccessRateData>
                            </div>
                            <Percentage style={{ fontSize: "bigger" }}>{(successRate.totalSuccess / successRate.totalSearches * 100).toFixed(2)} %</Percentage>
                        </SuccessRateContainer> : <CircularProgress className='circular-progress'></CircularProgress>}
                    </Card>
                </PieChartCard>
            </ChartContainerGrid>
        </MainContent >
    );
}

export default SearchPanel;