import { config } from "../config/config";
import { ICompany } from '@imisight-types/company';
import axiosService from "./axios.service";

const featureUsageEndPoint: string | undefined = config.GATEWAY_URL;

export const fetchAllCompanies = () => {
    return axiosService.api.get<ICompany[]>(`${featureUsageEndPoint}/companies`)
        .then((response) => { return response.data as ICompany[] })
        .catch((error) => { console.log(error); return null; });
}
