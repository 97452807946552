import { FeatureUsageTypes } from '../../components/Shared/Models/feature-usage.enums';
import { createSelector, createSlice, } from '@reduxjs/toolkit';

interface FeaturesState {
    allFeatures: { key: string; value: string, selected: boolean }[];
    selectedFeature: { key: string; value: string } | null;
}

const initialState: FeaturesState = {
    allFeatures: Object.entries(FeatureUsageTypes).map((feature) => { return { key: feature[0], value: feature[1], selected: false } }),
    selectedFeature: null
};

const featuresSlice = createSlice({
    name: 'features',
    initialState: initialState,
    reducers: {
        setSelectedFeature: (state, action) => {
            state.allFeatures = state.allFeatures.map((feature) => { return { ...feature, selected: feature.key === action.payload?.key } })
            state.selectedFeature = action.payload;
        },
    }
});
export const { setSelectedFeature } = featuresSlice.actions;

const getState = (state: any) => state.features as FeaturesState;

export const getSelectedFeature = createSelector(getState, (state) => state.selectedFeature);
export const getAllFeatures = createSelector(getState, (state) => state.allFeatures);

export default featuresSlice.reducer;