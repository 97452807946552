
import "./top-menu.css";
import { Icon, ListItemIcon, ListItemText, Menu, MenuItem } from "@mui/material";
import { useState } from "react";
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import MenuIcon from '@mui/icons-material/Menu';

const TopMenu = ({ items }: any) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = (func?: Function) => {
        setAnchorEl(null);
        if (func) {
            func();
        }

    };
    return (
        <>
            <Icon onClick={handleClick}>
                {open ? <MenuOpenIcon /> : <MenuIcon />}
            </Icon>

            <Menu
                id="top-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={() => handleClose()}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                {items.map((item: any, index: number) => {
                    return item.isVisibale &&
                        <MenuItem key={index} onClick={() => handleClose(item.customFunction)}>
                            <ListItemIcon>
                                <item.icon></item.icon>
                            </ListItemIcon>
                            <ListItemText>{item.title}</ListItemText>
                        </MenuItem>
                }
                )}
            </Menu>
        </>

    )
}

export default TopMenu