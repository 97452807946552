import styled from 'styled-components';
import Router from '../Router/router';
import Header from './header';
import FeatureSelectorContainer from '../FeatureSelectorContainer/Feature-selector-container';
import { useEffect } from 'react';
import { fetchAllCompanies } from '../../services/company.service';
import { ICompany } from '@imisight-types/company';
import { useDispatch } from 'react-redux';
import { setAllCompanies } from '../../store/slices/main-content.slice';

const ContentWrapper = styled.div`
  display: grid;
  height: auto;
  grid-template-columns: 12% 1fr;
  align-self: stretch;

  @media (max-width: 1200px) {
    grid-template-columns: 16% 1fr;
  }

`;

const MainContent = styled.div`
  background-color: #EBEFF3;
  margin: 0;
  overflow: auto;
  padding: 0;
  height: 100%;
  justify-content: strech;
  align-items: stretch;
  display: flex;

  @media (max-width: 1200px) {
    padding: 0.5rem;
  }
`;

const MainPage = styled.div`
  display: grid;
  height: 100vh;
  grid-template-rows: 3rem calc(100vh - 3rem);
`;

const Layout = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    fetchAllCompanies().then((companies: ICompany[] | null) => {
      dispatch(setAllCompanies(companies))
    });
  });

  return (
    <MainPage>
      <Header />
      <ContentWrapper>
        <FeatureSelectorContainer>
        </FeatureSelectorContainer>
        <MainContent className='main-content'>
          <Router>
          </Router>
        </MainContent>
      </ContentWrapper>
    </MainPage >
  );
};

export default Layout;
